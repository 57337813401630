<template>
  <div class="empty-state">
    <div :class="['icon-container', iconColor]">
      <i :class="[iconClass]"></i>
    </div>
    <h2 class="mt-4 font-sans text-lg font-bold text-center text-black-base">
      {{ title }}
    </h2>
    <p class="text-center text-black-light">
      {{ text }}
    </p>
    <ButtonPrimary
      class="btn-position"
      @click.native="$emit('cta')"
      v-if="useCallToAction"
      :text="btnText"
    />
    <router-link v-else-if="useLink" class="btn-primary btn-position" :to="linkPath">
      {{ btnText }}
    </router-link>
  </div>
</template>
<script>
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
export default {
  name: "EmptyState",
  components: {
    ButtonPrimary,
  },
  props: {
    iconClass: {
      type: String,
      default: "bx bx-time",
    },
    title: {
      type: String,
      default: "Title",
    },
    text: {
      type: String,
      default: "Body text",
    },
    btnText: {
      type: String,
      default: "Button text",
    },
    useLink: {
      type: Boolean,
      default: false,
    },
    useCallToAction: {
      type: Boolean,
      default: false,
    },
    linkPath: {
      type: String,
      default: "/",
    },
    iconColor: {
      type: String,
      default: "teal",
    },
  },
};
</script>
<style scoped>
.empty-state {
  @apply p-6 border rounded-lg border-grey-base sm:p-16;
  @apply bg-white;
}

.icon-container {
  @apply flex items-center justify-center;
  @apply w-32 h-32 p-8;
  @apply relative left-1/2 transform -translate-x-1/2;
  @apply text-3xl;
  @apply rounded-full;
}

.teal {
  @apply bg-teal-lighter text-teal-base;
}

.green {
  @apply bg-green-lighter text-green-base;
}

.red {
  @apply bg-red-lighter text-red-base;
}
.btn-primary {
  @apply font-sans shadow rounded-md font-semibold border-teal-dark border hover:bg-teal-dark transition bg-teal-base duration-300 ease-in-out text-white h-11;
  @apply focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2 focus:ring-teal-base focus:border-teal-base;
  @apply inline-flex items-center justify-center px-3;
}

.btn-position {
  @apply relative px-8 mt-6 transform -translate-x-1/2 left-1/2;
}
</style>

<template>
  <div>
    <label :for="name" class="">
      <span v-if="showLabel" class="label">
        {{ label }}
      </span>

      <span v-if="showDescription" class="description">
        {{ description }}
      </span>
      <div v-if="showImgGroup" class="img-group">
        <figure class="img-container">
          <img
            src="@/assets/img/partner-agent-photos/Adili_Ubuntu_Voyages_Travel_Afrique.jpg"
            alt=""
          />
        </figure>
        <figure class="img-container">
          <img
            src="@/assets/img/partner-agent-photos/Travel-Afrique-Alexandra-Travel-Hanta.jpg"
            alt=""
          />
        </figure>
        <figure class="img-container">
          <img
            src="@/assets/img/partner-agent-photos/travel-afrique-joseph-kafunda.jpg"
            alt=""
          />
        </figure>
      </div>
      <div
        :class="[isError ? 'border-red-base' : 'border-grey-dark']"
        class="border-style"
      >
        <EmptyState
          class="border-none"
          :title="title"
          :text="text"
          faIconClass="bx bxs-file-blank"
        />

        <input
          class="absolute invisible w-0 h-0 overflow-hidden"
          :accept="allowedFileTypes"
          type="file"
          :name="name"
          :id="name"
          ref="file"
          @change="selectFile($event.target.name, $event.target.files)"
        />
      </div>
    </label>

    <div
      v-if="showFile"
      class="
        flex
        justify-between
        w-full
        p-3
        mt-3
        border
        rounded-md
        border-grey-base
      "
    >
      <div class="flex items-center">
        <i class="bx bx-file-blank text-teal-base"></i>
        <p class="ml-3">{{ fileName }}</p>
      </div>

      <ButtonIcon
          iconClass="bx bx-trash"
        customStyle="text-red-base hover:bg-red-lighter transition duration-300 block py-2 px-3 focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2 focus:ring-red-darker rounded-full"
        @click.native="removeFile()"
      />
    </div>
  </div>
</template>

<script>
import EmptyState from "@/components/EmptyState";
import ButtonIcon from "@/components/buttons/ButtonIcon";

export default {
  name: "InputUpload",
  inheritAttrs: false,
  components: { EmptyState, ButtonIcon },
  data() {
    return {
      fileName: "",
      file: "",
      showFile: false,
      files: [],
    };
  },
  props: {
    allowedFileTypes: {
      type: String,
      default: ".jpg, .jpeg, .png, .pdf",
    },
    showImgGroup: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Title",
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "Description",
    },
    label: {
      type: String,
      default: "Label",
    },
    text: {
      type: String,
      default: "Text",
    },
    name: {
      type: String,
      default: "file",
    },
  },
  methods: {
    getFile(event) {
      this.files = event.target.files;
      if (event.target.files.length > 0) {
        (this.showFile = true), (this.fileName = event.target.files[0].name);
      }
    },
    removeFile() {
      this.files = [];
      this.showFile = false;
      this.fileName = "";
    },
    selectFile(name, file) {
      let imageFile = file[0];

      if (file.length > 0) {
        // let formData = new FormData();
        // let imageURL = URL.createObjectURL(imageFile);

        this.showFile = true;
        this.fileName = file[0].name;

        // formData.append(name, imageFile);

        // this.$emit('upload', imageFile.name);
        this.$emit("upload", imageFile);
      }
    },
  },
};
</script>
<style scoped>
.label {
  @apply block font-sans font-semibold text-black-base;
}
.description {
  @apply block mt-1 font-sans text-sm text-black-lighter;
}
.border-style {
  @apply mt-3 border border-dashed rounded cursor-pointer hover:bg-grey-lighter;
}

.img-group {
  @apply relative w-full mt-4 mb-4;
}

.img-container {
  @apply h-28 w-28 sm:h-32 sm:w-32 overflow-hidden rounded-full relative inline-block -mr-8 border-4 border-white;
}
</style>
<template>
  <div>
    <nav class="navbar">
      <div class="navbar-wrapper">
        <ButtonIcon @click.native="$router.go(-1)"/>
        <ProgressIndicator :currentPage="2" :totalPages="3"/>
      </div>
    </nav>

    <form
        @submit.prevent="submit"
        class="w-full px-4 pb-48 mt-10 md:px-12"
        enctype="multipart/form-data"
    >
      <div class="form-page">
        <h2 class="form-page-title">
          {{ $t("business_request.page_2.sub_title") }}
        </h2>
        <div class="w-full mb-6 sm:mb-8">
          <InputText
              :label="$t('business_request.page_2.company_name.label')"
              name="company-name"
              :placeholder="
              $t('business_request.page_2.company_name.placeholder')
            "
              v-model="form.companyName"
              :disabled="loading"
              :errorMsg="companyNameErrMsg"
              :showErrMsg="$v.form.companyName.$error"
              @blur="$v.form.companyName.$touch()"
              class="mb-6 sm:mb-8"
          />

          <InputText
              :label="$t('business_request.page_2.email.label')"
              :placeholder="$t('business_request.page_2.email.placeholder')"
              :disabled="loading"
              type="email"
              name="email"
              @blur="$v.form.companyEmail.$touch()"
              :errorMsg="emailErrMsg"
              :showErrMsg="$v.form.companyEmail.$error"
              v-model="form.companyEmail"
              class="mb-6 sm:mb-8"
          />

          <InputText
              :label="$t('business_request.page_2.position.label')"
              name="position"
              :placeholder="$t('business_request.page_2.position.placeholder')"
              v-model="form.position"
              :disabled="loading"
              :errorMsg="positionErrMsg"
              :showErrMsg="$v.form.position.$error"
              @blur="$v.form.position.$touch()"
              class="mb-6 sm:mb-8"
          />

          <h4 class="form-page-inter-title">
            {{ $t("business_request.page_2.intertitle") }}
          </h4>

          <!-- USE GOOGLE AUTOCOMPLETE PLACES API -->
          <InputText
              :label="$t('business_request.page_2.street.label')"
              name="street"
              :placeholder="$t('business_request.page_2.street.placeholder')"
              class="mb-6 sm:mb-8"
              v-model="form.address"
              :disabled="loading"
              :errorMsg="addressErrMsg"
              :showErrMsg="$v.form.address.$error"
              @blur="$v.form.address.$touch()"
          />

          <InputText
              :label="$t('business_request.page_2.zip.label')"
              type="number"
              name="street"
              :placeholder="$t('business_request.page_2.zip.placeholder')"
              v-model="form.zipcode"
              :disabled="loading"
              :errorMsg="zipCodeErrMsg"
              :showErrMsg="$v.form.zipcode.$error"
              @blur="$v.form.zipcode.$touch()"
              class="mb-6 sm:mb-8"
          />

          <InputText
              :label="$t('business_request.page_2.location.label')"
              name="location"
              :placeholder="$t('business_request.page_2.location.placeholder')"
              class="mb-6 sm:mb-8"
              v-model="form.location"
              :disabled="loading"
              :errorMsg="locationErrMsg"
              :showErrMsg="$v.form.location.$error"
              @blur="$v.form.location.$touch()"
          />


          <SelectDropdown
              :description="
              $t('business_request.page_2.headquarters.placeholder')
            "
              :label="$t('business_request.page_2.headquarters.label')"
              :optionsList="countries"
              :showLabel="true"
              v-model="form.headquarters"
              :disabled="loading"
              :errorMsg="hqErrMsg"
              :showErrMsg="$v.form.headquarters.$error"
              @blur="$v.form.headquarters.$touch()"
          />

          <div class="mb-6 sm:mb-8">
            <InputUpload
                name="company-doc"
                :label="$t('business_request.page_2.upload.label')"
                :title="$t('business_request.page_2.upload.title')"
                :text="$t('business_request.page_2.upload.text')"
                v-model="form.companyDoc"
                :isError="$v.form.companyDoc.$error"
                @input="$v.form.companyDoc.$touch()"
                @upload="upload"
            />

            <span
                class="mt-2 font-sans text-sm font-medium error-msg text-red-base"
                v-if="$v.form.companyDoc.$error && !$v.form.companyDoc.requiredIf"
            >
              {{ $t("business_request.page_2.upload.error") }}
            </span>
          </div>
        </div>
        <ButtonPrimary :text="$t('trip_request.button')" class="desktop-cta"/>
      </div>

      <div class="bottom-nav">
        <div class="bottom-nav-wrapper">
          <ButtonPrimary
              class="block h-12 px-8 w-full"
              :text="$t('business_request.button')"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import InputUpload from "@/components/inputs/InputUpload";
import SelectDropdown from "@/components/dropdowns/SelectDropdown";

const {
  required,
  // alpha,
  // minLength,
  // alphaNum,
  email,
  numeric,
  requiredIf,
  // helpers,
} = require("vuelidate/lib/validators");
import countriesList from "@/data/countries/countries.json";
import {mapGetters} from "vuex";
import ButtonIcon from "@/components/buttons/ButtonIcon";

// const alphaNumValidator = helpers.regex('alphaNumVal', /^[a-zA-Z0-9\\-\\s]+$/);

export default {
  name: "company-details",
  components: {
    ButtonIcon,
    InputUpload,
    SelectDropdown,
  },
  data() {
    return {
      countriesList,
      form: {
        companyName: "",
        position: "",
        headquarters: "",
        address: "",
        location: "",
        zipcode: "",
        companyEmail: "",
        companyDoc: "",
      },
      loading: false,
    };
  },
  validations: {
    form: {
      companyName: {
        required,
        // alphaNum,
        // minLength: minLength(2),
      },
      position: {
        required,
        // alpha,
        // minLength: minLength(2),
      },
      companyEmail: {
        required,
        email,
        // alpha,
        // minLength: minLength(2),
      },
      address: {
        required,
        // alphaNumValidator,
        // alphaNum,
        // minLength: minLength(2),
      },
      location: {
        required,
        // alpha,
        // minLength: minLength(2),
      },
      zipcode: {
        required,
        numeric,
      },
      headquarters: {
        required,
      },
      companyDoc: {
        required: requiredIf(function () {
          return !this.form.companyDoc;
        }),
      },
    },
  },
  computed: {
    countries() {
      let countries;
      if (localStorage.lang === "fr") countries = this.countriesList.fr;
      else countries = this.countriesList.en;
      countries = countries.sort((a, b) => a.name.localeCompare(b.name));
      return countries;
    },
    ...mapGetters({
      companyDetails: "partner/companyDetails"
    }),
    companyNameErrMsg() {
      let msg;
      if (!this.$v.form.companyName.required) {
        msg = this.$t("business_request.page_2.company_name.error"); //Company Name is required
      }
      // if (!this.$v.form.companyName.minLength) {
      //   msg = `Company name must have at least ${this.$v.form.companyName.$params.minLength.min} characters`;
      // }
      // if (!this.$v.form.companyName.alphaNum) {
      //   msg = 'Company name must only contain alpha-numeric characters';
      // }
      return msg;
    },
    positionErrMsg() {
      let msg;
      if (!this.$v.form.position.required) {
        msg = this.$t("business_request.page_2.position.error"); //Position is required
      }
      // if (!this.$v.form.position.minLength) {
      //   msg = `Position must have at least ${this.$v.form.position.$params.minLength.min} characters`;
      // }
      // if (!this.$v.form.position.alpha) {
      //   msg = 'Position must only contain alphabetic characters';
      // }
      return msg;
    },
    addressErrMsg() {
      let msg;
      if (!this.$v.form.address.required) {
        msg = this.$t("business_request.page_2.street.error"); //Address is required
      }
      // if (!this.$v.form.address.minLength) {
      //   msg = `Address must have at least ${this.$v.form.address.$params.minLength.min} characters`;
      // }
      // if (!this.$v.form.address.alphaNum) {
      //   msg = 'Address must only contain alpha-numeric characters';
      // }
      return msg;
    },
    locationErrMsg() {
      let msg;
      if (!this.$v.form.location.required) {
        msg = this.$t("business_request.page_2.location.error"); //Location is required
      }
      // if (!this.$v.form.location.minLength) {
      //   msg = `Location must have at least ${this.$v.form.location.$params.minLength.min} characters`;
      // }
      // if (!this.$v.form.location.alpha) {
      //   msg = 'Location must only contain alpha characters';
      // }
      return msg;
    },
    zipCodeErrMsg() {
      let msg;
      if (!this.$v.form.zipcode.numeric) {
        msg = this.$t("business_request.page_2.zip.error_1"); //ZIP code must only contain numeric characters";
      }
      if (!this.$v.form.zipcode.required) {
        msg = this.$t("business_request.page_2.zip.error_2"); //ZIP code is required
      }
      return msg;
    },
    hqErrMsg() {
      let msg;
      if (!this.$v.form.headquarters.required) {
        msg = this.$t("business_request.page_2.headquarters.error"); //Headquarters is required
      }
      return msg;
    },
    emailErrMsg() {
      let msg;
      if (!this.$v.form.companyEmail.email) {
        msg = this.$t("business_request.page_2.email.error_1"); //Please enter a valid email
      }
      if (!this.$v.form.companyEmail.required) {
        msg = this.$t("business_request.page_2.email.error_2"); //Email is required";
      }
      return msg;
    },
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return false;
      }
      this.$store.commit("partner/SET_COMPANY_DETAILS", this.form);
      this.$router.push("/business/operations");
    },
    upload(val) {
      this.form.companyDoc = val;
    },
  },
  mounted() {
    if (Object.keys(this.companyDetails).length) {
      this.form = this.companyDetails;
    }
  },
};
</script>
<style scoped>

.form-page-inter-title {
  @apply mt-12 mb-6 font-sans text-sm font-semibold uppercase text-grey-dark;
}

.navbar {
  @apply top-0 flex flex-wrap items-center w-full h-20 px-4 bg-white md:px-12;
}

.navbar-wrapper {
  @apply relative flex items-center justify-between w-full h-12 max-w-screen-md transform -translate-x-1/2 left-1/2;
}

.form {
  @apply w-full px-4 pb-48 mt-10 md:px-12;
}

.form-page {
  @apply relative max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.form-page-title {
  @apply mb-12 font-sans text-xl font-bold  sm:text-2xl text-black-base;
}

.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white;
  @apply border-t border-grey-base sm:mt-16;
}

.bottom-nav-wrapper {
  @apply relative flex items-center justify-between;
  @apply max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
  @apply block sm:hidden;
}

.bottom-nav-wrapper {
  @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.desktop-cta {
  @apply hidden sm:block h-12 px-8 w-full mt-8;
}
</style>
<template>
  <label :for="selectName">
    <span v-if="showLabel" class="label">{{ label }}</span>
    <div class="select-container">
      <i :class="[iconClass, 'bx-sm']" v-if="showIcon"></i>
      <select
        :class="[
          showErrMsg ? 'border-red-base' : 'border-grey-dark',
          'select-field',
          showIcon ? 'show-icon' : 'show-no-icon',
        ]"
        :disabled="disabled"
        :name="selectName"
        :id="selectName"
        @change="$emit('input', $event.target.value)"
        v-bind="$attrs"
      >
        <option v-if="showDescription" selected disabled value="">
          {{ description }}
        </option>
        <option
          v-for="(option, index) in optionsList"
          :key="index"
          :value="
            !phone ? option.value : `${option.name} (${option.dial_code})`
          "
          :selected="option.selected"
          :disabled="option.disabled"
        >
          {{ !phone ? option.name : `${option.name} (${option.dial_code})` }}
        </option>
      </select>
    </div>
    <ErrorMessage v-if="showErrMsg" :errorMsg="errorMsg" class="mt-2" />
  </label>
</template>
<script>
import ErrorMessage from "@/components/ErrorMessage";
export default {
  components: {
    ErrorMessage,
  },
  name: "SelectDropdown",
  inheritAttrs: false,
  props: {
    showIcon: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: "bx bx-world",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    selectName: {
      type: String,
    },
    description: {
      type: String,
    },
    label: {
      type: String,
      default: "Label",
    },
    optionsList: {
      type: Array,
    },
    showErrMsg: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: "",
    },
    phone: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
  },
};
</script>
<style scoped>
label {
  @apply block;
}

.label {
  @apply text-black-base font-sans font-semibold text-base mb-3 inline-block;
}
.show-icon{
  @apply pl-12 pr-2;
}
.select-field {
  @apply rounded-lg border focus:border-teal-base focus:ring-teal-lighter focus:ring-2 py-2.5 w-full;
  @apply z-10 w-full relative bg-transparent;
  @apply overflow-ellipsis pr-8;
}

.select-field[disabled]{
  @apply bg-grey-light border-grey-base;
  @apply text-black-lightest;
}


.show-no-icon{
  @apply px-2.5;
}
.select-container {
  @apply relative;
}
.select-container i{
  @apply text-black-lightest;
  @apply absolute top-1/2 transform -translate-y-1/2;
  @apply left-3;
}
</style>
